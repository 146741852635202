<template>
  <div
    ref="selectorContainer"
    class="input selector"
    :class="(multiple && selectedOption?.length) || (!multiple && selectedOption !== null) ? 'option-selected' : ''"
  >
    <Listbox
      v-slot="{ open }"
      v-model="selectedOption"
      as="template"
      :name="name"
      :multiple="multiple"
      
      :by="compareItems"
      :disabled="disabled"
    >
      <label v-if="label" class="label input-label selector-label">
        {{ label }}
      </label>
      <VDropdown
        :id="`selector-${name}`"
        ref="selectorDropdown"
        class="relative fix-popper-button fix-popper-width"
        :class="[errorMessage ? 'input-error' : '', containerClass]"
        strategy="fixed"
        auto-size="min"
        popper-class="reset-dropdown"
        :show="open"
        :distance="0"
        @show="() => { emit('open'); addScrollListener() }"
        @hide="() => { removeScrollListener() }"
      >
        <ListboxButton
          :id="`selector-button-${name}`"
          class="selector-button flex items-center justify-between"
          :class="[
            open ? 'selector-buttonOpen pseudo-focus' : 'selector-buttonClosed',
            multiple ? 'flex items-center flex-wrap gap-2' : ''
          ]"
          @keydown.escape="$event => open ? $event.stopPropagation() : null"
        >
          <template v-if="multiple">
            <span
              v-if="!selectedOption?.length || !showValue"
              class="block truncate selector-buttonText selector-buttonTextDefault"
            >
              {{ defaultLabel }}
            </span>
            <template v-else>
              <span
                v-for="(option, index) in selectedOption"
                :key="index"
                class="chips-primary max-w-[calc(100%-10px)] z-[3]"
              >
                <span class="truncate max-w-full">{{
                  textCallbackWithDefault(option) || option
                }}</span>
                <Icon
                  :path="mdiClose"
                  class="close-icon"
                  @click.prevent="selectedOption.splice(index, 1)"
                />
              </span>
            </template>
          </template>
          <template v-else>
            <span
              class="block truncate selector-buttonText"
              :class="[
                (selectedOption && showValue)
                  ? 'selector-buttonTextSelected'
                  : 'selector-buttonTextDefault'
              ]"
            >
              {{
                (showValue
                  ? (selectedOption && selectedOption[text]) || selectedOption
                  : false) || defaultLabel
              }}
            </span>
          </template>
          <span
            class="pointer-events-none flex items-center -mr-0.5"
          >
            <slot name="chevron-icon" :open="open">
            <Icon
              :path="(open ? mdiChevronUp : mdiChevronDown)"
              :class="[
                open
                  ? 'selector-buttonIconOpen'
                  : 'selector-buttonIconClosed',
                'selector-buttonIcon'
              ]"
            />
            </slot>
          </span>
        </ListboxButton>
        <template #popper>
          <div
            v-if="loadingOptions"
            class="loader relative cursor-default select-none py-2 px-4 text-neutral"
          >
            <Icon
              :path="mdiLoading"
              class="text-neutral-light h-5 w-5 mx-auto animate-spin"
            />
          </div>
          <ListboxOptions
            v-model="selectedOption"
            static
            class="field-dropdown z-[4] mt-1 max-h-[500px] min-w-full overflow-auto rounded-md bg-white py-1 text-base font-normal shadow sm:text-sm outline-0"
            :class="dropdownClass"
            @blur="validate"
          >
            <template v-for="(option, index) in selectorOptions">
              <li
                v-if="option.divider"
                :key="`divider-${index}`"
                class="divider"
              ></li>
              <ListboxOption
                v-else
                v-slot="{ active, selected }"
                :key="index"
                :value="option"
                :disabled="option.disabled"
                as="template"
              >
                <li
                  :class="[
                    'selector-option',
                    'relative cursor-default select-none py-2 pl-10 pr-4',
                    option.disabled ? 'opacity-50': '',
                    active
                      ? 'selector-optionActive'
                      : 'selector-optionDefault',
                  ]"
                  @click="deselect(option, selected)"
                >
                  <span class="block truncate">
                    <slot
                      name="option"
                      v-bind="typeof option === 'object' ? option : { option }"
                    >
                      {{ (option && (option as Record<string, any>)[text]) || option }}
                    </slot>
                  </span>
                  <span
                    v-if="selected"
                    class="absolute inset-y-0 left-0 flex items-center pl-3"
                    :class="[
                      active
                        ? 'selector-optionIconActive'
                        : 'selector-optionIconDefault',
                      'selector-optionIcon'
                    ]"
                  >
                    <Icon v-if="selected" :path="mdiCheckBold" />
                    <!-- <Icon v-else-if="!selected" :path="''" /> -->
                  </span>
                </li>
              </ListboxOption>
            </template>
            <div
              v-if="localOptions.length === 0"
              class="text-neutral-light text-center p-2"
            >
              <slot name="no-options">
                No hay opciones disponibles
              </slot>
            </div>
          </ListboxOptions>
        </template>
      </VDropdown>
    </Listbox>
    <span v-if="errorMessage" class="input-errorContainer">
      {{ errorMessage }}
    </span>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue';
import { mdiCheckBold, mdiChevronDown, mdiChevronUp, mdiClose, mdiLoading } from '@mdi/js';
import { Dropdown as VDropdown } from 'floating-vue';
import { useField } from 'vee-validate';
import type { PropType } from 'vue';

import 'floating-vue/dist/style.css';

import type { Rule } from '@/composables/use-rules';
import type { SelectOption } from '@/types/common';
import { compareArrays, getSelectorItemValue } from '@/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Value = any;
type Item = Record<string, Value>
type Options = (string | SelectOption<Value>)[];

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [Object, String, Number, Array] as PropType<Value>,
    default: () => null
  },
  options: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: Array as PropType<Options>,
      default: () => []
  },
  loadingOptions: {
    type: Boolean
  },
  getOptions: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: [Function, Object] as PropType<((page: number) => Promise<Options>) | null>,
    default: null
  },
  deselectable: {
    type: Boolean,
    default: () => false
  },
  usePagination: {
    type: [Boolean, Number] as PropType<boolean | number>,
    default: () => false
  },
  textCallback: {
    type: Function as PropType<(option: unknown) => string>
  },
  rules: {
    type: Array as PropType<Rule[]>,
    default: () => []
  },
  name: {
    type: String,
    default: () => 'mySelector'
  },
  title: {
    type: String,
    default: () => null
  },
  emptyTitle: {
    type: String,
    default: () => null
  },
  multiple: {
    type: Boolean,
    default: () => false
  },
  disabled: {
    type: Boolean
  },
  text: {
    type: String,
    default: 'text'
  },
  showValue: {
    type: Boolean,
    default: true
  },
  dropdownClass: {
    type: String,
    default: ''
  },
  containerClass: {
    type: String,
    default: ''
  },
  closeOnSelect: {
    type: [Boolean, Object] as PropType<boolean | null>,
    default: () => null
  },
  closeOnScroll: {
    type: Boolean,
    default: () => true
  }
});

type Emits = {
  (e: 'update:modelValue', value: Value, oldValue: Value): void;
  (e: 'item-selected', item: Item, oldItem: Item): void;
  (event: 'validate', value: boolean | string): void;
  (e: 'open'): void;
  (e: 'close'): void;
};

const emit = defineEmits<Emits>();

const { t } = useI18n();

const selectorContainer = ref<HTMLDivElement | null>(null);

const selectorDropdown = ref<{ show: () => void, hide: () => void } | null>(null);

const selectedOption = ref<Value>(null);

const defaultLabel = computed(() => {
  if (!localOptions.value?.length) {
    return props.emptyTitle || t('common.noOptionsAvailable');
  }
  return props.title || t('common.select');
});

const page = ref(0); // TODO: implement pagination

function compareItems(a: unknown, b: unknown) {
  const aValue = (a?.value || a)?.toString();
  const bValue = (b?.value || b)?.toString();
  if (aValue && bValue) {
    return aValue.toLowerCase() === bValue.toLowerCase();
  }
  return a === b;
}

const localOptions = ref<Options>([]);

watch([() => props.options, () => props.getOptions, page], async () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let optionsResult: Options = [];
  if (props.getOptions) {
    optionsResult = await props.getOptions(page.value);
  } else {
    optionsResult = props.options;
  }

  if (!Array.isArray(optionsResult)) {
    console.error('Options must be an array', optionsResult);
    localOptions.value = [];
  }

  localOptions.value = optionsResult.map(option => {
    if (typeof option === 'object') {
      return option;
    }
    return {
      value: option,
      text: option
    };
  });
}, {
  immediate: true
});

const textCallbackWithDefault = computed(() => {
  return props.textCallback || ((option: unknown) => (option as Record<string, string>)[props.text]);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectorOptions = computed<SelectOption<any>[]>(() => {
  return localOptions.value.map(option => {
    if (typeof option === 'string') {
      return {
        value: option,
        text: option
      };
    }
    return option;
  }).filter(option => !option.hidden);
});

const {
  errorMessage,
  value: validateValue,
  validate: validateField
} = useField(props.name, useRules(props.rules));

let hasValidated = false;

const validate = async (isExternalCall = false, force = false) => {
  await new Promise(resolve => setTimeout(resolve, 100));
  if (!isExternalCall || hasValidated || force) {
    hasValidated = true;
    validateField();
  }
  if (!isExternalCall) {
    emit('validate', errorMessage.value || false);
  }
};

function close() {
  // selectorDropdown.value?.hide();
  const triggerElement = selectorContainer.value?.parentElement;
  setTimeout(() => {
    triggerElement?.dispatchEvent(new Event('mousedown'));
    triggerElement?.dispatchEvent(new Event('click'));
    triggerElement?.click();
  }, 100);
}

watch(
  selectedOption,
  (item, oldItem) => {

    const shouldClose = props.multiple ? props.closeOnSelect : props.closeOnSelect !== false;

    if (shouldClose) {
      close();
      // emit('close');
    }

    if (props.multiple) {
      const values = item?.map(getSelectorItemValue);
      if (compareArrays(values, props.modelValue)) {
        return;
      }
      validateValue.value = values;
      emit(
        'update:modelValue',
        values,
        (oldItem || []).map(getSelectorItemValue)
      );
    } else {
      const value = getSelectorItemValue(item);
      const oldValue = getSelectorItemValue(oldItem);
      validateValue.value = value;
      if (!props.multiple && value === props.modelValue) {
        return;
      }
      emit('update:modelValue', value, oldValue);
    }
    emit('item-selected', item, oldItem);
  },
  { deep: true }
);

watch(
  () => props.modelValue,
  value => {
    if (props.multiple) {
      const valuesFromSelected = selectedOption.value?.map(getSelectorItemValue);
      if (compareArrays(valuesFromSelected, value)) {
        return;
      }
      if (!Array.isArray(value)) {
        value = value === null || value === undefined ? [] : [value];
      }
      selectedOption.value = value.map((v: Value) => {
        const foundItem = localOptions.value?.find(o => {
          if (typeof o !== 'object') {
            return o === v;
          }
          return o.value === v;
        });
        return foundItem || v;
      });
    } else {
      if (selectedOption.value && value && selectedOption.value.value === value) {
        return;
      }
      const foundItem = localOptions.value?.find(o => {
        if (typeof o !== 'object') {
          return o === value;
        }
        return o.value === value;
      });
      selectedOption.value = foundItem || value;
    }
  },
  { immediate: true, deep: true }
);

function deselect(option: { disabled?: boolean }, selected: boolean) {
  if (props.deselectable && !props.multiple && !option.disabled && selected) {
    nextTick(() => {
      selectedOption.value = props.multiple ? [] : null;
    });
  }
}

function addScrollListener () {
  if (!props.closeOnScroll) {
    return;
  }
  window.addEventListener('scroll', close);
};

function removeScrollListener () {
  if (!props.closeOnScroll) {
    return;
  }
  window.removeEventListener('scroll', close);
};

defineExpose({
  validate
});
</script>

<style lang="scss">
.hide-icon .selector-buttonIcon {
  display: none !important;
}

.fix-popper-button {
  width: 100%;
  height: auto;
  // width: calc(100% + 24px);
  // height: calc(100% + 24px);
}

.fix-popper-width>.popper{
  min-width: 100%;
}
</style>

<style scoped lang="scss">
.input.selector > div:nth-of-type(2) {
    display: none;
}
</style>
